import { TargetStatus } from './target-status-summary.model';
import { CampaignLiveTargetCheck } from './campaign-live.model';
import { CTFTaskStatus } from './ctf/ctf-task-status.model';
import { BaseModel } from '../shared/base.model';

export class SmartCityBuilding extends BaseModel {
  id: string;
  buildingType?: string;
  address?: string;
  dependencies?: string[];
  target?: SmartCityTargetData;
  task?: SmartCityTaskData;
  media?: BuildingMedia;
}

export class SmartCityTargetData extends BaseModel {
  id: string;
  name: string;
  targetChecks: CampaignLiveTargetCheck[];
  isUnderAttack: boolean;
  status: TargetStatus;
}

export class SmartCityTaskData extends BaseModel {
  id: string;
  title: string;
  status: CTFTaskStatus;
  score: number;
}

export class BuildingMedia extends BaseModel {
  good?: BuildingMediaData;
  compromised?: BuildingMediaData;
  notAvailable?: BuildingMediaData;
}

export class BuildingMediaData extends BaseModel {
  id: string;
  type: SmartCityMediaType;
}

export class SmartCityBuildingSaveDTO extends BaseModel {
  type?: string;
  address?: string;
  dependencies?: string[];
  goodMediaId?: string;
  compromisedMediaId?: string;
  notAvailableMediaId?: string;
  targetId?: string;
  taskId?: string;
  previousTargetId?: string;
  previousTaskId?: string;
  applyForAllTeams: boolean;
}

export class SmartCityBuildingDetails extends BaseModel {
  id: string;
  type?: string;
  address?: string;
  dependencies?: string[];
  media?: BuildingMedia;
}

export class SmartCityMapConfiguration extends BaseModel {
  name: string;
  sceneFile: string;
  scenePath: string;
  fogStart: number;
  fogEnd: number;
  height: number;
  depth: number;
  radioTower?: { coordinates: Coordinates[]; scale: number };
  waterTreatmentPlant?: {
    coordinates: { x: number; y: number; z: number };
    scale: number;
  };
  drone?: {
    coordinates: { x: number; y: number; z: number };
    buildingId: string;
    affectedAnimationName?: string;
    scale: number;
  };
  hasRiverDam?: boolean;
  airport?: {
    coordinates: Coordinates;
    rotation: Coordinates;
    scale: number;
  };
  customLogo?: CustomLogoConfiguration;
  substation?: { coordinates: Coordinates; rotation: Coordinates; scale: number };
  traffic?: { file: string; coordinates: Coordinates; scale: number };
}

export class CustomLogoConfiguration {
  coordinates: Coordinates;
  fileName: string;
  scale: number;
}

export class Coordinates {
  x: number;
  y: number;
  z: number;
}

export enum SmartCityMediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export class SmartCityEventLog extends BaseModel {
  name: string;
  timestamp: Date;
  status: SmartCityEventStatus;
  metadata?: SmartCityEventLogMetaData;
}

export class SmartCityEventLogMetaData extends BaseModel {
  building: SmartCityEventLogBuildingData;
  target?: SmartCityEventLogTargetData;
  task?: SmartCityEventLogTaskData;
}

export class SmartCityEventLogBuildingData extends BaseModel {
  id: string;
  type?: string;
  address?: string;
}

export class SmartCityEventLogTargetData extends BaseModel {
  name: string;
}

export class SmartCityEventLogTaskData extends BaseModel {
  score: number;
}

export enum SmartCityEventStatus {
  ATTACK_UNASSIGNED = 'ATTACK_UNASSIGNED',
  UNDER_ATTACK = 'UNDER_ATTACK',
  COMPROMISED = 'COMPROMISED',
  FAILED = 'FAILED',
  SOLVED = 'SOLVED',
}

export class SmartCityDataResult extends BaseModel {
  isHealthy: boolean;
  data: SmartCityBuilding[];

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('data') && data.data != null) {
      this.data = data.data.map((it) => new SmartCityBuilding(it));
    }
  }
}
