import { Component, OnInit } from '@angular/core';
import { SmartCityService } from '../../../services/gamenet/smart-city.service';
import {
  ExerciseService,
  IntervalService,
  MissionBoardService,
  TargetService,
} from '../../../services';
import {
  BlueTeam,
  CTFTaskDTO,
  Exercise,
  ExerciseType,
  SmartCityBuildingDetails,
  SmartCityEventLog,
  SmartCityMapConfiguration,
  SmartCityTargetData,
  SmartCityTaskData,
  Target,
} from '../../../models';
import { forkJoin, Observable, of, Subscription, switchMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterStateModel, FilterStateService } from '../../../shared';
import { SmartCityBuilding } from '../../../models';
import { first, take } from 'rxjs/operators';

@Component({
  selector: 'isa-smart-city',
  templateUrl: './smart-city.component.html',
  styleUrls: ['./smart-city.component.scss'],
})
@UntilDestroy()
export class SmartCityComponent implements OnInit {
  teams: BlueTeam[] = [];
  cities: SmartCityMapConfiguration[] = [
    {
      name: 'Abu Dhabi',
      sceneFile: 'AbuDhabi.gltf',
      scenePath: 'assets/3D_geo/AbuDhabi/',
      fogStart: 30,
      fogEnd: 50,
      height: 30,
      depth: 20,
      waterTreatmentPlant: {
        coordinates: { x: -9, y: 0, z: -4.5 },
        scale: 0.7,
      },
      radioTower: {
        coordinates: [
          { x: 2.1, y: 0, z: 5 },
          { x: -4.5, y: 0, z: -5 },
          { x: -6, y: 0, z: -12 },
          { x: -9.1, y: 0, z: 3 },
          { x: -15, y: 0, z: 9 },
        ],
        scale: 0.6,
      },
      drone: {
        coordinates: { x: -12, y: 0.1, z: -1 },
        buildingId: 'ID_airport',
        affectedAnimationName: 'Finnair',
        scale: 0.3,
      },
      airport: {
        coordinates: { x: -14, y: 0.1, z: -4.5 },
        rotation: { x: 0, y: -0.65, z: 0 },
        scale: 0.03,
      },
      substation: {
        coordinates: { x: -4.15, y: 0, z: 10.85 },
        rotation: { x: 0, y: 0.85, z: 0 },
        scale: 0.4,
      },
      traffic: {
        file: 'abud_dhabi_roads.glb',
        coordinates: { x: 2.3, y: 1, z: 6.75 },
        scale: 0.15,
      },
    },
    {
      name: 'Baltimore',
      sceneFile: 'Boston_city.gltf',
      scenePath: 'assets/3D_geo/',
      fogStart: 30,
      fogEnd: 50,
      height: 20,
      depth: 20,
    },
    {
      name: 'London',
      sceneFile: 'London.gltf',
      scenePath: 'assets/3D_geo/London/',
      fogStart: 20,
      fogEnd: 40,
      height: 30,
      depth: 20,
      drone: {
        coordinates: { x: 7, y: 2, z: 2 },
        buildingId: 'ID_10912',
        scale: 0.6,
      },
      radioTower: {
        coordinates: [
          { x: -10, y: 0, z: 8.5 },
          { x: -19.5, y: 0, z: 8 },
          { x: 9.65, y: 0, z: -6.5 },
          { x: -7.5, y: 0, z: -5.5 },
          { x: 14.5, y: 0, z: 9.5 },
        ],
        scale: 1.2,
      },
      waterTreatmentPlant: {
        coordinates: { x: 5.9, y: 0, z: 15 },
        scale: 1,
      },
      customLogo: {
        fileName: 'CYBEXER_logo.gltf',
        coordinates: { x: -0.27, y: -0.09, z: -0.7 },
        scale: 1,
      },
      substation: {
        coordinates: { x: -0.5, y: 0, z: 0 },
        rotation: { x: 0, y: 0.8, z: 0 },
        scale: 0.6,
      },
    },
    {
      name: 'Tartu',
      sceneFile: 'TartuCity.gltf',
      scenePath: 'assets/3D_geo/Tartu/',
      hasRiverDam: true,
      fogStart: 60,
      fogEnd: 90,
      height: 40,
      depth: 40,
      radioTower: {
        coordinates: [
          { x: -7.5, y: 0, z: 3.5 },
          { x: 9, y: 0, z: -5 },
          { x: 12, y: 0, z: 11 },
        ],
        scale: 1,
      },
      waterTreatmentPlant: {
        coordinates: { x: -7.4, y: 0, z: 12 },
        scale: 1,
      },
      drone: {
        coordinates: { x: -15, y: 2, z: 8 },
        buildingId: 'ID_airport1',
        affectedAnimationName: 'Finnair',
        scale: 0.5,
      },
      customLogo: {
        fileName: 'CYBEXER_logo.gltf',
        coordinates: { x: 0.45, y: -0.09, z: -1.49 },
        scale: 1,
      },
    },
  ];
  selectedCity = this.cities[0];
  isLogOpen = false;
  isInfoPanelOpen = this.smartCityService.isInfoPanelOpen;
  filter$: Observable<Partial<FilterStateModel>>;
  eventLogs: SmartCityEventLog[] = [];
  selectedBuildingDetails: SmartCityBuildingDetails;
  selectedTarget?: SmartCityTargetData;
  selectedTask?: SmartCityTaskData;
  exercise: Exercise;
  targets: Target[];
  ctfTasks: CTFTaskDTO[];
  teamId: string;
  cityState: any;
  selectedMeshId: string;
  intervalSubscription: Subscription;

  constructor(
    private smartCityService: SmartCityService,
    private intervalService: IntervalService,
    private exerciseService: ExerciseService,
    private targetService: TargetService,
    private missionBoardService: MissionBoardService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.filter$ = this.filterStateService.filter$('team');
    this.getExercise();
    this.filter$.pipe(untilDestroyed(this)).subscribe((filter) => {
      if (filter.team) {
        this.teamId = filter.team;
        this.loadData();
      }
    });
  }

  getExercise() {
    this.exerciseService
      .getActiveExercise()
      .pipe(untilDestroyed(this))
      .subscribe((exercise) => {
        this.exercise = exercise;
        this.teams = exercise.blueTeams;
        this.filterStateService.setFilterIfEmptyOrDefault('team', this.exercise.blueTeams[0].id);
      });
  }

  loadData() {
    if (this.exercise == null || this.teamId == null) return;
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }

    this.getTargets(this.teamId);
    this.getTasks(this.teamId);

    this.intervalSubscription = this.intervalService
      .getWidgetRefreshInterval()
      .pipe(
        switchMap(() => {
          return forkJoin([
            this.smartCityService.getData(this.exercise.id, this.teamId),
            this.smartCityService.getEventLogs(this.exercise.id, this.teamId),
          ]);
        }),
        untilDestroyed(this)
      )
      .subscribe(([cityState, logs]) => {
        if (cityState && cityState.isHealthy) {
          this.cityState = cityState.data;
        }
        if (logs) {
          this.eventLogs = logs;
        }
      });
  }

  getTasks(team: string) {
    if (this.exercise.type !== ExerciseType.CAMPAIGN) {
      this.missionBoardService
        .getData(this.exercise.id, team)
        .pipe(first())
        .subscribe((mission) => {
          this.ctfTasks = mission.tasks;
        });
    }
  }

  getTargets(team: string) {
    if (this.exercise.type !== ExerciseType.CTF) {
      this.targetService
        .getTargets(this.exercise.id, team)
        .pipe(first())
        .subscribe((targets) => (this.targets = targets.targets));
    }
  }

  getDataByID(id: string): SmartCityBuilding {
    if (!this.cityState) return;
    return this.cityState.find((item: any) => item.id === id);
  }

  showInfoPanel(buildingId: string) {
    this.selectedMeshId = buildingId;
    const buildingWidgetData = this.getDataByID(buildingId);
    this.selectedTarget = buildingWidgetData?.target;
    this.selectedTask = buildingWidgetData?.task;

    const buildingDetails$ =
      buildingWidgetData != null
        ? of(this.convertToDetails(buildingWidgetData))
        : this.smartCityService
            .getBuildingDetails(this.exercise.id, this.teamId, buildingId)
            .pipe(take(1));

    buildingDetails$.subscribe((details) => {
      this.selectedBuildingDetails = details;
      this.isInfoPanelOpen.set(true);
    });
  }

  convertToDetails(building: SmartCityBuilding): SmartCityBuildingDetails {
    const details = new SmartCityBuildingDetails();

    details.id = building.id;
    details.type = building.buildingType;
    details.address = building.address;
    details.dependencies = building.dependencies;
    details.media = building.media;

    return details;
  }
}
